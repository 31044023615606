var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('admin-dashboard-layout',[_c('router-link',{attrs:{"to":{name: 'admin.users.index'}}},[_c('v-btn',{staticClass:"mb-5",attrs:{"text":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-arrow-left")]),_vm._v(" Return to users list ")],1)],1),(_vm.user)?_c('v-card',{attrs:{"outlined":"","rounded":""}},[_c('v-card-title',[_c('v-row',[_c('v-col',[_vm._v(" "+_vm._s(_vm.user.name)+" ")])],1)],1),_c('v-card-text',[(_vm.user.account_type==='standard')?_c('v-alert',{staticClass:"my-6 pa-4",attrs:{"icon":"mdi-information","outlined":"","text":"","type":"info"}},[_vm._v("This account has a standard type. To manage this account you need to go to Stripe.")]):_vm._e(),_c('v-form',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"disabled":""},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"disabled":"","label":"Number of Submissions per month cycle"},model:{value:(_vm.user.total_submissions),callback:function ($$v) {_vm.$set(_vm.user, "total_submissions", $$v)},expression:"user.total_submissions"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Account created on","disabled":""},model:{value:(_vm.user.created_at),callback:function ($$v) {_vm.$set(_vm.user, "created_at", $$v)},expression:"user.created_at"}})],1)],1),(_vm.user.account_type!=='standard')?_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"clearable":"","hint":"Select price plan for this user","items":_vm.pricePlans,"label":"Select price plan","persistent-hint":"","item-value":"id","return-object":"","single-line":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.name)+" "),(item.interval)?[_vm._v("("+_vm._s(item.interval)+")")]:_vm._e()],2)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.name)+" "),(item.interval)?[_vm._v("("+_vm._s(item.interval)+")")]:_vm._e()],2)]}}],null,false,482924188),model:{value:(_vm.selectedPricePlan),callback:function ($$v) {_vm.selectedPricePlan=$$v},expression:"selectedPricePlan"}}),(!_vm.selectedPricePlan && _vm.changed)?_c('v-alert',{staticClass:"my-6 pa-4",attrs:{"icon":"mdi-information","outlined":"","text":"","type":"info"}},[_vm._v("User will be on the Free account.")]):_vm._e()],1)],1):_vm._e(),_c('v-btn',{staticClass:"mt-5",attrs:{"color":"primary","elevation":"0","loading":_vm.loading,"disabled":_vm.disabled},on:{"click":_vm.update}},[_vm._v("Save Changes")])],1)],1),(_vm.loadingUser)?_c('v-overlay',[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"32"}})],1):_vm._e()],1):_vm._e(),(_vm.dates)?_c('v-card',{staticClass:"pa-4 my-4",attrs:{"elevation":"0","outlined":""}},[_c('div',{staticClass:"mb-4 d-flex"},[_c('div',{staticClass:"text-h6"},[_vm._v("Usages")])]),_c('div',{staticClass:"mb-4"},[_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.dates,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.dates=$event},"update:return-value":function($event){_vm.dates=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Picker in dialog","prepend-icon":"mdi-calendar","readonly":"","outlined":"","hide-details":"auto"},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}},'v-text-field',attrs,false),on))]}}],null,false,3396600316),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-date-picker',{attrs:{"scrollable":"","range":""},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.handleUsageDatesChange}},[_vm._v(" OK ")])],1)],1)],1),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"usage-card"},[_c('div',{staticClass:"text-caption"},[_vm._v("Submissions")]),_c('div',{staticClass:"text-subtitle-1"},[_vm._v(_vm._s(this.usage? this.usage.submissions: 0))])]),_c('div',{staticClass:"usage-card"},[_c('div',{staticClass:"text-caption"},[_vm._v("Generated Follow Ups")]),_c('div',{staticClass:"text-subtitle-1"},[_vm._v(_vm._s(this.usage? this.usage.gptCallsCount: 0))])]),_c('div',{staticClass:"usage-card"},[_c('div',{staticClass:"text-caption"},[_vm._v("Used Tokens")]),_c('div',{staticClass:"text-subtitle-1"},[_vm._v(_vm._s(this.usage? this.usage.gptTokens: 0))])]),_c('div',{staticClass:"usage-card"},[_c('div',{staticClass:"text-caption"},[_vm._v("Provider Cost")]),_c('div',{staticClass:"text-subtitle-1"},[_vm._v(_vm._s(this.usage? this.usage.gptTotalCost: 0))])])])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }