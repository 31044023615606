<template>
    <admin-dashboard-layout>
        <router-link  :to="{name: 'admin.users.index'}">
            <v-btn class="mb-5" text >
                <v-icon left>mdi-arrow-left</v-icon>
                Return to users list
            </v-btn>
        </router-link>
        <v-card outlined rounded v-if="user">
            <v-card-title>
                <v-row>
                    <v-col>
                        {{user.name}}
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-alert v-if="user.account_type==='standard'" icon="mdi-information" outlined class="my-6 pa-4" text type="info">This account has a standard type. To manage this account you need to go to Stripe.</v-alert>
                <v-form>
                    <v-row>
                        <v-col>
                            <v-text-field v-model="user.email" disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="user.total_submissions" disabled
                                label="Number of Submissions per month cycle"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="Account created on" v-model="user.created_at" disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row v-if="user.account_type!=='standard'">
                        <v-col>
                            <v-select
                                clearable
                                v-model="selectedPricePlan"
                                :hint="`Select price plan for this user`"
                                :items="pricePlans"
                                label="Select price plan"
                                persistent-hint
                                item-value="id"
                                return-object
                                single-line
                            >
                                <template slot="selection" slot-scope="{ item }">
                                    <span>
                                        {{ item.name }}
                                        <template v-if="item.interval">({{ item.interval }})</template>
                                    </span>
                                </template>
                                <template slot="item" slot-scope="{ item }">
                                    <span>
                                        {{ item.name }}
                                        <template v-if="item.interval">({{ item.interval }})</template>
                                    </span>
                                </template>
                            </v-select>
                            <v-alert v-if="!selectedPricePlan && changed" icon="mdi-information" outlined class="my-6 pa-4" text type="info">User will be on the Free account.</v-alert>
                        </v-col>
                    </v-row>
                

                    <v-btn color="primary" elevation="0" :loading="loading" :disabled="disabled" class="mt-5" @click="update">Save Changes</v-btn>
                </v-form>
            </v-card-text>
            <v-overlay v-if="loadingUser">
                <v-progress-circular
                    indeterminate
                    size="32"
                ></v-progress-circular>
            </v-overlay>
        </v-card>
        <v-card v-if="dates" elevation="0" class="pa-4 my-4" outlined>
            <div class="mb-4 d-flex">
                <div class="text-h6 ">Usages</div>
            </div>
    
            <div class="mb-4">
                <v-dialog
                    ref="dialog"
                    v-model="modal"
                    :return-value.sync="dates"
                    persistent
                    width="290px"
                >
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="dates"
                        label="Picker in dialog"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        hide-details="auto"
                    ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="dates"
                        scrollable
                        range
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primary"
                        @click="modal = false"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click="handleUsageDatesChange"
                    >
                        OK
                    </v-btn>
                    </v-date-picker>
                </v-dialog>
            </div>
            <div class="d-flex">
                <div class="usage-card">
                    <div class="text-caption">Submissions</div>
                    <div class="text-subtitle-1">{{this.usage? this.usage.submissions: 0}}</div>
                </div>
                <div class="usage-card">
                    <div class="text-caption">Generated Follow Ups</div>
                    <div class="text-subtitle-1">{{this.usage? this.usage.gptCallsCount: 0}}</div>
                </div>
                <div class="usage-card">
                    <div class="text-caption">Used Tokens</div>
                    <div class="text-subtitle-1">{{this.usage? this.usage.gptTokens: 0}}</div>
                </div>
                <div class="usage-card">
                    <div class="text-caption">Provider Cost</div>
                    <div class="text-subtitle-1">{{this.usage? this.usage.gptTotalCost: 0}}</div>
                </div>
            </div>
        </v-card>
    </admin-dashboard-layout>
</template>

<script>
    import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout.vue";
    import AdminApi from "@/api/AdminApi";
    import axios from 'axios'
    import { mapMutations } from 'vuex';

    export default {
        name: "users-show",
        components: {AdminDashboardLayout},
        data: function () {
            return {
                user: {},
                pricePlans: [],
                selectedPricePlan: null,
                loadingUser: false,
                loading: false,
                loadingUsage: false,
                usage: null,
                modal: false,
                dates: null,
            }
        },

        computed:{
            changed(){
                if(this.user){
                    return this.user.price_plan_id !== (this.selectedPricePlan? this.selectedPricePlan.id: null)
                }
                return false
            },
            disabled(){
                return this.loading || !this.changed || this.user.account_type=='standard'
            },
            submissions(){
                return this.usage? this.usage.submissions: 0
            },
            followupsNumber(){
                return this.usage? this.usage.gptCallsCount: 0
            },
            followupsTokens(){
                return this.usage? this.usage.gptTokensCount: 0
            },
            followupsExpense(){
                return this.usage? this.usage.gptExpense: 0
            },
            
        },
        methods: {
            ...mapMutations({
                showSnackBar: 'showSnackBar',
            }),
            async update() {
                try {
                    if(this.user){
                        this.loading = true
                        const res = await axios.post(`/api/admin/users/${this.user.id}`, {
                            price_plan_id: this.selectedPricePlan? this.selectedPricePlan.id: null
                        });
                        if(res.data && res.data.user){
                            this.user = res.data.user
                            this.showSnackBar({
                                text: "User information is updated.", 
                                color: 'success', 
                                timeout: 2000
                            })
                        }
                    }
                } catch (error) {
                    this.showSnackBar({
                        text: "Can't update user info.", 
                        color: 'error', 
                        timeout: 2000
                    })
                    console.error('user update',error);
                } finally {
                    this.loading = false
                }   
            },
            handleUsageDatesChange(){
                this.$refs.dialog.save(this.dates)
                this.fetchUsage()
            },
            async fetchUsage(){
                try {
                    this.loadingUsage = true
                    const res = this.dates? await AdminApi.getUserUsage(this.$route.params.id, this.dates[0], this.dates[1]) :  await AdminApi.getUserUsage(this.$route.params.id)
                    this.usage = res.data
                    this.loadingUsage = false
                    return res.data
                } catch (error) {
                    this.loadingUsage = false
                } 
            },
        },
        async mounted() {
            try {
                const userId = this.$route.params.id;
                this.loadingUser = true 
                const res = await AdminApi.getUserById(userId);
                this.user = res.data;
                const pricesRes = await AdminApi.getPricePlans();
                this.pricePlans = pricesRes.data
                this.loadingUser = false 
                if (this.user.price_plan_id) {
                    this.selectedPricePlan = this.pricePlans.find(o => o.id === parseInt(this.user.price_plan_id));
                }

                const usage = await this.fetchUsage();
                if(usage){
                    this.dates = [this.$date(usage.startDate).format('YYYY-MM-DD'), this.$date(usage.endDate).format('YYYY-MM-DD')]
                }
            } catch (error) {
                console.log(error);
                this.loadingUser = false 
            }
        },
    }
</script>

<style scoped>
.usage-card{
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 16px;
    margin-right: 16px;
}
</style>
